<template>
  <v-card>
    <v-card-title>Image Data</v-card-title>
    <v-card-text>
      <v-form>
        <v-select
          :items="dataType"
          item-text="title"
          item-value="key"
          v-model="dataToSend.dataType"
          v-on:change="toggleData(dataToSend.dataType)"
          label="Data Type"
        />

        <v-autocomplete v-if="dataToSend.dataType == 'UserProfiling'"
          :items="userProfilings"
          item-text="name"
          item-value="_id"
          autocomplete
          v-model="dataToSend.userProfilingId"
          label="User Profiling"
        >
          <template slot='selection' slot-scope='{ item }'>
            {{ $languageProcessor.getEnText(item.name) }}
          </template>

          <template slot='item' slot-scope='{ item }'>
            {{ $languageProcessor.getEnText(item.name) }}
          </template>
        </v-autocomplete>

        <v-autocomplete v-if="dataToSend.dataType == 'UserActivity'"
          :items="projects"
          item-text="name"
          item-value="_id"
          autocomplete
          v-model="dataToSend.projectId"
          v-on:change="getActivity()"
          label="Project"
        >
          <template slot='selection' slot-scope='{ item }'>
            {{ $languageProcessor.getEnText(item.name) }}
          </template>

          <template slot='item' slot-scope='{ item }'>
            {{ $languageProcessor.getEnText(item.name) }}
          </template>
        </v-autocomplete>

        <v-row>
          <v-col>
            <v-text-field
              label="Page Number"
              v-model="pageNumber"
              type="number"
            />
          </v-col>

          <v-col>
            <v-select
              label="Photos Per Page"
              :items="[10, 25, 50, 100]"
              v-model="limit"
              @input="getImageData(pageNumber)"
            />

          </v-col>

          <v-col>
            <v-text-field 
              v-if="(dataToSend.projectId && dataToSend.dataType == 'UserActivity' && dataToSend.projectId == '61d5751c656bd8313b016405')"
              v-model="dataToSend.userId"
              label="User Id"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-autocomplete v-if="(dataToSend.projectId && dataToSend.dataType == 'UserActivity')"
          :items="activities"
          item-text="name"
          item-value="_id"
          autocomplete
          v-model="dataToSend.activityId"
          label="User Activity"
        >
          <template slot='selection' slot-scope='{ item }'>
            {{ $languageProcessor.getEnText(item.name) }}
          </template>

          <template slot='item' slot-scope='{ item }'>
            {{ $languageProcessor.getEnText(item.name) }}
          </template>
        </v-autocomplete>

        <v-btn :disabled="!(dataToSend.activityId || dataToSend.userProfilingId)" color="primary" @click="getImageData(pageNumber)">Load</v-btn>
      </v-form>

      <v-card-title primary-title v-if="loaded" class="mt-4">
        <div>
          <div class="headline">{{ imageData.totalDocs }}</div>
          <span class="grey--text">Total Images</span>
        </div>
      </v-card-title>

      <v-col cols="12" center>
        <v-row>
          <div v-for="(images, index) in imageData[dataToSend.dataType]" :key="index" class="pa-2">
            <v-card>
              <v-img v-if="loaded && images.questionType == 'imageUrlQuestion' && images.answer && images.answer != ''"
                height="200px"
                width="200px"
                :src="images.answer"
              />

              <v-img v-else-if="loaded && images.questionType == 'imageUrlQuestion' && images.answer && images.answer != ''"
                height="200px"
                width="200px"
                :src="`data:image/jpeg;base64,${images.answer}`"
              />

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon :href="generateRedirectUrl(dataToSend.dataType, images)" target="_blank" v-bind="attrs" v-on="on">
                      <v-icon color="green">mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View submission</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="removeAndReject(images)" v-bind="attrs" v-on="on">
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Reject submission</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="deactivateUserAndReject(images)" v-bind="attrs" v-on="on">
                      <v-icon color="red">mdi-account-cancel</v-icon>
                    </v-btn>
                  </template>
                  <span>Deactivate user</span>
                </v-tooltip>
              </v-card-actions>

            </v-card>
          </div>
        </v-row>
      </v-col>
    
      <v-pagination class="text-xs-center mt-5" v-if="imageData.totalDocs"
        @input="pageNumber => getImageData(pageNumber)"
        :length="Math.ceil(imageData.totalDocs/limit)"
        :total-visible="10"
        v-model="imageData.page"
      ></v-pagination>
    </v-card-text>
  </v-card>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";
const service = new RestResource();
export default {
  data() {
    return {
      pageNumber: 1,
      limit: 25,
      dataKey: {
        "UserActivity": "userActivityDataId",
        "UserProfiling": "userProfilingDataId"
      },
      dataType: [
        { title: "User Activity", key: "UserActivity" },
        { title: "User Profiling", key: "UserProfiling" }
      ],
      dataToSend: {},
      projects: [],
      userProfilings: [],
      activities: [],
      imageData: [],
      loaded: false,
    };
  },

  methods: {
    generateRedirectUrl(dataType, row) {
      if (dataType == "UserActivity") {
        return `/mobile/userActivity/${row.userId}/${row.projectId}/submissions?activityId=${row.activityId}&userActivityDataId=${row.userActivityDataId}`
      } else if (dataType == "UserProfiling") {
        return `/mobile/userProfiling/${row.userId}/${row.userProfilingId}/submissions?userProfilingDataId=${row.userProfilingDataId}`
      } else {
        return "ERROR"
      }
    },

    toggleData(dataType) {
      (dataType == "UserProfiling") ? this.getUserProfilings() : this.getProjects()
      this.dataToSend = { dataType }
    },

    getUserProfilings() {
      service.getUserProfilings().then(r => {
        this.userProfilings = r.data
      })
    },

    getProjects() {
      service.fetchProjects().then(r => {
        this.projects = r.data;
      });
    },

    getActivity() {
      service.fetchActivities({ projectId: this.dataToSend.projectId }).then(r => {
        this.activities = r.data;
      });
    },

    getImageData(pageNumber) {
      this.pageNumber = pageNumber

      this.$setLoader();
      service.getImageData({ ...this.dataToSend, page: pageNumber, limit: this.limit }).then(r => {
        this.imageData = r.data;
        this.$disableLoader();
        this.loaded = true;
      });
    },

    removeAndReject(image) {
      let data = this.imageData[this.dataToSend.dataType]
      let position = data.findIndex(r => r.userProfilingDataId == image.userProfilingDataId)

      if (position > -1) {
        this.$setLoader();

        service.changeUPDataStatus({ 
          userProfilingId: image.userProfilingId,
          userProfilingDataId: image.userProfilingDataId,
          userId: image.userId,
        }).then(() => {
          data.splice(position, 1)
          this.$disableLoader();
        })
      }
    },

    deactivateUserAndReject(image) {
      let data = this.imageData[this.dataToSend.dataType]
      let position = data.findIndex(r => r.userProfilingDataId == image.userProfilingDataId)

      if (position > -1) {
        this.$setLoader();
  
        service.changeUPDataStatus({ 
          userProfilingId: image.userProfilingId,
          userProfilingDataId: image.userProfilingDataId,
          userId: image.userId,
        })

        service.deactivateUser({
          userId: image.userId,
          reason: `Rejected UP (${image.userProfilingId}) submission (${image.userProfilingDataId}) at (${image.submissionAt})`,
          doneBy: this.$store.state.userProfile.name,
          createdAt: new Date()
        }).then(() => {
          data.splice(position, 1)
          this.$disableLoader();
        })
      }
    }
  }
};
</script>

